import Vue from 'vue'
import { localize } from 'vee-validate'
import ptBR from 'vee-validate/dist/locale/pt_BR.json'

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

import VueTheMask from 'vue-the-mask'
import VueApexCharts from 'vue-apexcharts'
import money from 'v-money'
import OneSignal from 'onesignal-vue'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// forms components

// 3rd party plugins
import '@/libs/portal-vue'
import './libs/users'
import './libs/FormComponents'

import './libs/money-filter'
import './libs/percentage-filter'
import './libs/fontawesome'
import './components/i2/notification/toast'
import './registerServiceWorker'
import './libs/Search'
import PrimeVue from 'primevue/config'
import './libs/modals/confirm'

Vue.use(VueApexCharts)
Vue.use(PrimeVue)
Vue.component('Apexchart', VueApexCharts)

Vue.use(VueTheMask)
// BSV Plugin Registration
Vue.use(OneSignal)
Vue.use(VueMoment)
moment.defaultFormat = {
  pt_BR: 'DD/MM/YYYY',
}
Vue.use(money, {
  precision: 2,
  decimal: ',',
  thousands: '.',
  prefix: 'R$ ',
  suffix: ' #',
  masked: true,
})
moment.locale('pt_BR')
Vue.use(VueMoment, { moment })
Vue.use(BootstrapVueIcons)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
localize('pt_BR', ptBR)
new Vue({
  router,
  store,
  beforeMount() {
    this.$OneSignal.init({
      appId: process.env.VUE_APP_ONESIGNAL_APP_ID,
      safari_web_id: process.env.VUE_APP_ONESIGNAL_TOKEN_SAFARI,
      allowLocalhostAsSecureOrigin: true,
      serviceWorkerParam: '/push/onesignal/OneSignalSdkWorker.js',
      serviceWorkerPath: `${process.env.VUE_APP_ADMIN_URL}/push/onesignal/OneSignalSdkWorker.js`,
    })
  },
  render: h => h(App),
}).$mount('#app')
Vue.config.dateFormat = moment.defaultFormat
import './libs/msgboxConfirm'
import {BootstrapVueIcons} from "bootstrap-vue"
